require("normalize.css/normalize.css");
require("./styles/index.scss");

import $ from "jquery";
import LazyLoad from "vanilla-lazyload";
import Swiper, { Navigation, Pagination } from "swiper";
import moment from "moment";

Swiper.use([Navigation, Pagination]);

$(document).on("click", 'a[href*="#"]:not([href="#"])', function (event) {
  event.preventDefault();

  $("html, body").animate(
    {
      scrollTop: $($.attr(this, "href")).offset().top,
    },
    500
  );
});

$(".header-nav-handler").on("click", function (event) {
  event.preventDefault();
  $("body").toggleClass("nav-visible");
});

var marquee = $(".partners-marquee-list").html();
$(".partners-marquee-list").html(marquee + marquee + marquee + marquee);

var lazyLoadInstance = new LazyLoad();

var bestVideoSlider;
var bestVideoSliderOptions = {
  observer: true,
  observeParents: true,
  slidesPerView: 1,
  spaceBetween: 31,
  navigation: {
    nextEl: ".best-video-header-control-item-next",
    prevEl: ".best-video-header-control-item-prev",
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
    },
  },
  on: {
    activeIndexChange: function (swiper) {
      swiper.slides.forEach((element, index) => {
        if (index < swiper.activeIndex) element.classList.add("truly-prev");
        else if (index > swiper.activeIndex + 1) element.classList.add("truly-next");
        else element.classList.remove("truly-prev", "truly-next");
      });
    },
    init: function (swiper) {
      swiper.slides.forEach((element, index) => {
        if (index < swiper.activeIndex) element.classList.add("truly-prev");
        else if (index > swiper.activeIndex + 1) element.classList.add("truly-next");
        else element.classList.remove("truly-prev", "truly-next");
      });
    },
  },
};

bestVideoSlider = new Swiper(".best-video-slider", bestVideoSliderOptions);

$(".top-video-item").on("click", function (event) {
  event.preventDefault();
  $(this).addClass("active");
  $(this).find("iframe").attr("src", $(this).find("iframe").data("src"));
});

var librariesSlider;
var librariesSliderOptions = {
  slidesPerView: 2,
  spaceBetween: 40,
  navigation: {
    nextEl: ".libraries-slider-next",
    prevEl: ".libraries-slider-prev",
  },
  breakpoints: {
    800: {
      slidesPerView: 7,
    },
  },
};

var businessSlider;
var businessSliderOptions = {
  slidesPerView: 2,
  spaceBetween: 40,
  navigation: {
    nextEl: ".business-slider-next",
    prevEl: ".business-slider-prev",
  },
  breakpoints: {
    800: {
      slidesPerView: 7,
    },
  },
};

var infoSlider;
var infoSliderOptions = {
  slidesPerView: 2,
  spaceBetween: 40,
  navigation: {
    nextEl: ".info-slider-next",
    prevEl: ".info-slider-prev",
  },
  breakpoints: {
    800: {
      slidesPerView: 7,
    },
  },
};

var eduSlider;
var eduSliderOptions = {
  slidesPerView: 2,
  spaceBetween: 40,
  navigation: {
    nextEl: ".edu-slider-next",
    prevEl: ".edu-slider-prev",
  },
  breakpoints: {
    800: {
      slidesPerView: 7,
    },
  },
};

var publishersSlider;
var publishersSliderOptions = {
  slidesPerView: 2,
  spaceBetween: 40,
  navigation: {
    nextEl: ".publishers-slider-next",
    prevEl: ".publishers-slider-prev",
  },
  breakpoints: {
    800: {
      slidesPerView: 7,
    },
  },
};

var bloggersSlider;
var bloggersSliderOptions = {
  slidesPerView: 2,
  spaceBetween: 40,
  navigation: {
    nextEl: ".bloggers-slider-next",
    prevEl: ".bloggers-slider-prev",
  },
  breakpoints: {
    800: {
      slidesPerView: 7,
    },
  },
};

window.onload = function (event) {
  businessSlider = new Swiper(".business-slider", businessSliderOptions);
  librariesSlider = new Swiper(".libraries-slider", librariesSliderOptions);
  infoSlider = new Swiper(".info-slider", infoSliderOptions);
  eduSlider = new Swiper(".edu-slider", eduSliderOptions);
  publishersSlider = new Swiper(".publishers-slider", publishersSliderOptions);
  bloggersSlider = new Swiper(".bloggers-slider", bloggersSliderOptions);
};

window.onresize = function (event) {
  bestVideoSlider.destroy();
  bestVideoSlider = new Swiper(".best-video-slider", bestVideoSliderOptions);
  businessSlider.destroy();
  businessSlider = new Swiper(".business-slider", businessSliderOptions);
  librariesSlider.destroy();
  librariesSlider = new Swiper(".libraries-slider", librariesSliderOptions);
  infoSlider.destroy();
  infoSlider = new Swiper(".info-slider", infoSliderOptions);
  eduSlider.destroy();
  eduSlider = new Swiper(".edu-slider", eduSliderOptions);
  publishersSlider.destroy();
  publishersSlider = new Swiper(".publishers-slider", publishersSliderOptions);
  bloggersSlider.destroy();
  bloggersSlider = new Swiper(".bloggers-slider", bloggersSliderOptions);
};

$(".popup-close").on("click", function (event) {
  event.preventDefault;
  $(this).closest(".popup").removeClass("visible");
  if ($(this).closest(".popup").attr("id") == "video") {
    $(this).closest(".popup").find("iframe").attr("src", "");
  }
  return false;
});

$(document).on("click", ".popup-handler", function (event) {
  event.preventDefault;
  if ($(this).attr("href") == "#video") {
    $($(this).attr("href") + " iframe").attr("src", $(this).data("video-src"));
  }
  $($(this).attr("href")).addClass("visible");
  return false;
});

window.addEventListener("scroll", function () {
  if (window.scrollY > 0) {
    document.querySelector("body").classList.add("scrolled");
  } else {
    document.querySelector("body").classList.remove("scrolled");
  }
});

function truncateWithEllipses(text, max) {
  return text.substr(0, max - 1) + (text.length > max ? "&hellip;" : "");
}

var apiURL = "https://piterbook.com/wp-json";

moment.locale("ru");

var posts;

Promise.all([fetch(apiURL + "/wp/v2/broadcasts?order=asc&_embed&per_page=100&page=1").then((response) => response.json()), fetch(apiURL + "/wp/v2/broadcasts?order=asc&_embed&per_page=100&page=2").then((response) => response.json())]).then((response) => {
  posts = [].concat.apply([], response);
  var relativePosts = 0;
  $.each(posts, function (index, value) {
    var now = moment(new Date());
    var end = moment(moment(value.acf.datetime, "YYYY-MM-DD HH:mm:ss").toDate());
    var duration = moment.duration(now.diff(end));
    var hoursLeft = duration.asHours();
    if (value.acf.datetime && hoursLeft < 1) {
      relativePosts++;
      if (relativePosts > 11) {
        $("#broadcasts").append($('<a class="party-list-item party-list-item-more" href="./broadcast"><div class="party-list-item-title">И еще<br />множество<br />трансляций</div><div class="party-list-item-desc">Смотреть все</div></a>'));
        lazyLoadInstance.update();
        return false;
      }
      var item = $('<div class="broadcasts-list-item" />');
      var dateClass = moment(moment(value.acf.datetime, "YYYY-MM-DD HH:mm:ss").toDate()).format("D-MM");
      if (hoursLeft < -1 || !value.acf.ext_url) {
        var imageWrapper = $('<div class="broadcasts-list-item-image-wrapper" />');
      } else {
        var imageWrapper = $('<a class="broadcasts-list-item-image-wrapper broadcasts-list-item-image-wrapper-link popup-handler" />').attr("href", "#video").attr("data-video-src", value.acf.ext_url);
      }
      if (value._embedded) {
        if (value._embedded["wp:featuredmedia"][0].media_details.sizes["broadcast-cover"].source_url) {
          var imageSrc = value._embedded["wp:featuredmedia"][0].media_details.sizes["broadcast-cover"].source_url;
          var imageLqipSrc = value._embedded["wp:featuredmedia"][0].media_details.sizes.lqip.source_url;
        }
      } else {
        var imageSrc = "https://piterbook.com/wp-content/uploads/2021/10/top-video-back-2-800x424.png";
        var imageLqipSrc = "https://piterbook.com/wp-content/uploads/2021/10/top-video-back-2-16x16.png";
      }
      var image = $('<img class="broadcasts-list-item-image lazy" />').attr("src", imageLqipSrc).attr("data-src", imageSrc);
      var title = $('<div class="broadcasts-list-item-title" />').html(value.title.rendered);
      var desc = $('<div class="broadcasts-list-item-desc" />').html(truncateWithEllipses(value.acf.desc, 100));
      var footer = $('<div class="broadcasts-list-item-footer" />');
      var date = $('<div class="broadcasts-list-item-footer-date" />').text(moment(moment(value.acf.datetime, "YYYY-MM-DD HH:mm:ss").toDate()).format("D MMMM в H:mm"));
      if (hoursLeft > 0) {
        date.addClass("broadcasts-list-item-footer-date-live").text("В прямом эфире!");
      } else if (hoursLeft > -1) {
        date.addClass("broadcasts-list-item-footer-date-soon").prepend('<span class="broadcasts-list-item-footer-date-desc">Скоро</span> ');
      }
      item.append(imageWrapper.append(image)).append(title).append(desc).append(footer.append(date));
      $("#broadcasts").append(item);
    }
    if (index + 1 === posts.length) {
      lazyLoadInstance.update();
    }
  });
});
